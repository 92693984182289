import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import order_check from "../assets/order_status/order_check.svg"
import "./styles/find_check_order.css"

const CheckOrder = () => {
    const [order_code, setOrder_code] = useState('');
    const navigate = useNavigate();
    const [order, setOrder] = useState();

    const fetchAllOrders = () => {
      axios.get(`https://back.eltaqwarestaurant.com/admin/orders`, {
        headers: { 'Accept': 'application/json' }
      })
      .then(response => {
        console.log(response);
        console.log(response.data.data);
        setOrder(response.data.data[response.data.data.length - 1]);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        toast.error(error.response.data.message)
      });
    };
    
    useEffect(() => {
        fetchAllOrders();
    }, []);

    const handleInputChange = (e) => {
        const { value } = e.target;
        setOrder_code(value);
    };

    const fetchOrders = () => {
        axios.get(`https://back.eltaqwarestaurant.com/admin/orders/${order_code}`, {
            headers: { 'Accept': 'application/json' }
        })
            .then(response => {
                console.log(response.data.data.id);
                navigate(`/check/order/${response.data.data.id}`)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                toast.error("لا يوجد أوردر بهذا الكود")
            });
    };

    return (
        <div className='order_find'>
            {order ? (<>
                <div className='order_find_container'>
            <div className='order_find_img_holder'>
                <img src={order_check}/>
            </div>
            <h1>يمكنك نسخ كود الأوردر و الاستعلام به عن حالة الأوردر لاحقا</h1>
            <h2>{order.id}</h2>
        </div>
        </>):(<></>)}

        </div>
    );
};

export default CheckOrder;
