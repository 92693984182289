import React, { useState, useEffect } from 'react';
import Orders from './admin/Orders';
import CreateDish from './admin/CreateDish';
import CreateCategory from './admin/CreateCategory';
import "./styles/cart.css";

const Cart = ({ removeFromCart }) => {
  const [totalPriceState, setTotalPriceState] = useState(0);

  const [isNavOpen, setIsNavOpen] = useState(true);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
    console.log(JSON.parse(localStorage.getItem("cart")));
  };

  let order_items = JSON.parse(localStorage.getItem("cart"));

  useEffect(() => {
    if (order_items) {
        const totalPrice = order_items.reduce((total, orderItem) => {
            const finalPrice = parseFloat(orderItem.dish.final_price);
            const quantity = parseInt(orderItem.quantity);
            return total + (finalPrice * quantity);
        }, 0);

        // Check if the new total price is different from the current one
        if (totalPrice !== totalPriceState) {
            setTotalPriceState(totalPrice);
        }
    }
}, [order_items]);
  
  

  const handleRemoveItem = (index,e) => {
    e.preventDefault();
    removeFromCart(index);
  };

  return (
    <>
    {order_items ? (
      <>
      <button onClick={toggleNav} className='nav_btn'>
        <i className="fa-solid fa-cart-shopping"></i>
      <div className='notification'>{order_items.length}</div>
        
      </button>
      <div className={`admin_nav ${isNavOpen ? 'right' : ''}`}>
        <button onClick={toggleNav}><i className="fas fa-arrow-right"></i></button>
        <div className='cart_orders_container'>
          {order_items.map((orderItem, index) => (
            <a href="http://localhost:3000/s" className='cart_order' key={index}>
              <div className='cart_order_img_holder'>
              <img src={`https://back.eltaqwarestaurant.com/images/${orderItem.dish.image}`} />
              </div>
              <p className='cart_order_quantity'>x{orderItem.quantity}</p>
              <p className='cart_order_title'>{orderItem.dish.title.length > 15 ? `...${orderItem.dish.title.substring(0, 15)}` : orderItem.dish.title}</p>
              <h6 className='cart_order_price'>{orderItem.quantity * parseInt(orderItem.dish.final_price)} EGP</h6>
              <button className='remove_from_cart' onClick={(e) => handleRemoveItem(index, e)}><i class="fa-solid fa-minus"></i></button>
            </a>
          ))}
        </div>
        <h1 className={`cart_empty_title ${order_items.length === 0 ? 'shown' : ''}`}>السلة فارغة</h1>
        <div className={`cart_orders_action ${order_items.length === 0 ? 'hidden' : ''}`}>
          <a className='cart_orders_button' href="/checkout">اطلب الان</a>
          <div className='cart_orders_total'>
            <h1>الاجمالي</h1>
            <p>{totalPriceState} EGP</p> {/* Display total price here */}
          </div>
        </div>
      </div>
      </>
    ):(<>
    <button onClick={toggleNav} className='nav_btn'>
        <i className="fa-solid fa-cart-shopping"></i>
        
      </button>
      <div className={`admin_nav ${isNavOpen ? 'right' : ''}`}>
        <button onClick={toggleNav}><i className="fas fa-arrow-right"></i></button>
        <div className='cart_orders_container'>
        </div>
        <h1 className={`cart_empty_title shown`}>السلة فارغة</h1>
        <div className={`cart_orders_action hidden`}>
          <a className='cart_orders_button' href="/checkout">اطلب الان</a>
          <div className='cart_orders_total'>
            <h1>الاجمالي</h1>
            <p>{totalPriceState} EGP</p> {/* Display total price here */}
          </div>
        </div>
      </div>
    </>)}
    </>
  );
};

export default Cart;
