import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable, { SortOrder } from 'react-data-table-component';
import ImageUploader from 'react-image-upload';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import 'react-image-upload/dist/index.css';
import '../styles/create_dish.css';

const SliderEdit = () => {
  const [sliders, setSliders] = useState([]);
  const [dishes, setDishes] = useState([]);

  const fetchImages = () => {
    axios.get(`https://back.eltaqwarestaurant.com/admin/sliders`, {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => {
        console.log(response);
        setSliders(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        toast.error(error.response.data.message)
      });
  };

  useEffect(() => {
    fetchImages();
  }, []); // Empty dependency array to only run once


  const [slider, setSlider] = useState();

  const getImageFileObject = (imageFile) => {
    setSlider(imageFile.file);
    console.log(slider)
  };

  const runAfterImageDelete = (file) => {
    console.log({ file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', slider);

    console.log(formData)

    try {
      const response = await axios.post('https://back.eltaqwarestaurant.com/admin/sliders', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },

      });
      toast.success("تم اضافة الصورة بنجاح")
      fetchImages()
      setSlider();;
      console.log(response.data);
    } catch (error) {
      console.error('Error uploading file:', error.response.data.message);
      toast.error(error.response.data.message)
    }
  };

  const handleDeleteSlider = async (id, e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(`https://back.eltaqwarestaurant.com/admin/sliders/${id}`,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },

      });
      toast.success("تم مسح الطبق بنجاح")
      console.log(response.data);
      fetchImages()
    } catch (error) {
      console.error('Error uploading file:', error.response.data.message);
      toast.error(error.response.data.message)
    }
};

  const columns = [
    {
      name: 'الصورة',
      sortable: true,
      selector: row => <img className='small_img' src={`https://back.eltaqwarestaurant.com/images/${row.image}`} />
    },
    {
      name: 'قرارات',
      sortable: true,
      selector: row => <>
        <button onClick={(e) => handleDeleteSlider(row.id, e)} className='negative_karar'><i class="fa-solid fa-trash"></i></button>
      </>
    },



  ];

  return (
    <>
      {sliders? (<>
        <div className='create_delete'>
          <form className="create_form" onSubmit={handleSubmit}>
            <div className="create_form_container">
              <h1>اضافة خبر جديد</h1>
              <div className="input_holder">
                <label>صورة الخبر</label>
                <ImageUploader
                  className="input_img"
                  style={{ height: 220, width: 220, background: 'rgb(255 255 255)' }}
                  onFileAdded={(img) => getImageFileObject(img)}
                  onFileRemoved={(img) => runAfterImageDelete(img)}
                />
              </div>
              <input type="submit" className="input_submit" value="أضف الصورة" />
            </div>
            <ToastContainer />
          </form>
          <div className='table_div'>
            <DataTable
              columns={columns}
              data={sliders}
              pagination
              striped
              defaultSortAsc={false}
            />
            <Tooltip id="my-tooltip" place="top" />
          </div>
        </div>

      </>) : (<></>)}
    </>

  );
};

export default SliderEdit;
