import {useState,useEffect} from 'react'
import axios from 'axios';
import "./styles/single_dish.css"
import Countdown from 'react-countdown';
import InputSpinner from 'react-bootstrap-input-spinner'
import { Magnifier } from 'react-image-magnifiers';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const SingleDish = ({ addToCart }) => {
  const { id } = useParams()

  const [quantity, setQuantity] = useState(1)
  const [dish, setDish] = useState({});
  const [categories, setCategories] = useState();

  const fetchDish = () => {
    axios.get(`https://back.eltaqwarestaurant.com/admin/products/${id}`, {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => {
        console.log(response.data.data);
        setDish(response.data.data)
          axios.get(`https://back.eltaqwarestaurant.com/admin/categories/${response.data.data.category_id}`, {
            headers: { 'Accept': 'application/json' }
          })
            .then(response => {
              console.log(response.data.data.products);
              setCategories(response.data.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
            });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchDish();
  }, []);

  const handleAddToCart = () => {
    const item = { dish: dish, quantity: quantity };
    addToCart(item);
    toast.success("تم اضافة الطلب الي السلة")
  };

  return (
    <div className='single'>
      {dish && categories ? (<>
        <div className='single_container'>
    <Magnifier
        imageSrc={`https://back.eltaqwarestaurant.com/images/${dish.image}`}
        imageAlt={dish.title}
        dragToMove={false}
        mouseActivation="hover"
        className='single_img'
      />
      <div className='single_info'>
        <div className='single_title'>
            <h1>{dish.title}</h1>
            <div className='single_prices'>
                <h2 className='single_final_price'>{dish.final_price} EGP</h2>
                <h2 className='single_initial_price'>{dish.initial_price} EGP</h2>
            </div>
            <h3><span>الصنف :</span> {categories.title}</h3>
        </div>
        <p>{dish.description}</p>
        <div className='count_down'>
        <h2>عجلوا! بيع فلاش ينتهي قريبا!</h2>
        <Countdown date={Date.now() + 86400000}>
        </Countdown>
        </div>
        <div className='add_to_cart'>
        <div className='add_to_cart_nav'>
            <InputSpinner
    type={'real'}
    precision={1}
    max={10}
    min={1}
    step={1}
    value={quantity}
    onChange={setQuantity}
    variant={'dark'}
    size="sm"
/>
            </div>
            <button onClick={handleAddToCart} className='input_submit'>أضف للسلة <i class="fa-solid fa-cart-shopping"></i></button>

            
        </div>
      </div>
    </div></>):(<>جاري التحميل ...</>)}

    </div>
  )
}

export default SingleDish
