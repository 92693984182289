import { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import "../styles/admin.css";
import { useParams } from 'react-router-dom';

const SingleOrder = () => {
    const [status, setStatus] = useState('');

    const handleInputChange = (e) => {
        const { value } = e.target;
        setStatus(value);
    };
    const { id } = useParams()
    const [order, setOrder] = useState();

    const fetchOrders = () => {
        axios.get(`https://back.eltaqwarestaurant.com/admin/orders/${id}`, {
            headers: { 'Accept': 'application/json' }
        })
            .then(response => {
                console.log(response.data.data);
                setOrder(response.data.data);
                setStatus(response.data.data.status)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                toast.error(error.response.data.message)
            });
    };
    const handleChangeStatus = () => {
        axios.patch(`https://back.eltaqwarestaurant.com/admin/orders/${id}?status=${status}`, {
            headers: { 'Accept': 'application/json' }
        })
            .then(response => {
                console.log(response.data.data);
                toast.success("تم تغيير حالة الطلب")
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                toast.error(error.response.data.message)
            });
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    return (
        <div className='checkout'>
            {order ? (
                <>
                <div className='checkout'>
                    <div className='checkout_container'>
                        <h1 className='checkout_title'>بيانات الطلب</h1>
                        <div className='checkout_list'>
                        {order.order_details.map((orderItem, index) => (
                                <a href={`/product/${orderItem.product_id}`} className='cart_order' key={index}>
                                    <div className='order_info'>
                                    <div className='cart_order_img_holder'>
                                <img src={`https://back.eltaqwarestaurant.com/images/${orderItem.product_image}`} alt={orderItem.product_name} />
                            </div>
                                    <p className='cart_order_quantity'>x{orderItem.amount}</p>
                                    <p className='cart_order_title'>{orderItem.product_name}</p>
                                    <h6 className='cart_order_price'>{orderItem.amount * parseInt(orderItem.product_price)} EGP</h6>
                                    </div>
                                    <a href={`/product/${orderItem.product_id}`} className="positive_karar"><i class="fa-solid fa-eye"></i></a>
                                </a>
                            ))}
                            <div className='cart_orders_total checkout_total'>
                                <h1>الاجمالي</h1>
                                <p>{order.after_discount} EGP</p> {/* Display total price here */}
                            </div>
                        </div>
                        <div className='order_status_patch'>
                        <div className="input_holder">
                <label>تغيير حالة الطلب</label>
                <select
                  className="input_input"
                  name="category"
                  value={status}
                  onChange={handleInputChange}
                >
                  <option value="استلام">استلام</option>
                  <option value="تحضير">تحضير</option>
                  <option value="توصيل">توصيل</option>
                  <option value="تم">تم</option>
                </select>
                <button onClick={handleChangeStatus} className='input_submit'>تأكيد التغيير</button>
              </div>
                        </div>
                    </div>
                    <ToastContainer />
                    </div>
                    </>
            ) : (<></>)}

        </div>
    );
};

export default SingleOrder;
