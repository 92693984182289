import React, { useState,useEffect } from 'react';
import axios from 'axios';
import Orders from './admin/Orders';
import CreateDish from './admin/CreateDish';
import CreateCategory from './admin/CreateCategory';
import "./styles/cart.css";

const NavCategories = () => {
  const [isNavCOpen, setIsNavCOpen] = useState(true);
  const [categories, setCategories] = useState([]);
  const fetchCat = () => {
    axios.get(`https://back.eltaqwarestaurant.com/admin/categories`, {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => {
        console.log(response);
        setCategories(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };
  useEffect(() => {
    fetchCat();
  }, []);

  const toggleNav = () => {
    setIsNavCOpen(!isNavCOpen);
  };

  return (
    <>
    {categories ? (<>
      <button onClick={toggleNav} className='nav_categories'><i class="fa-solid fa-bars"></i> أصناف <i class="fa-solid fa-caret-down"></i></button>
      <button onClick={toggleNav} className='mobile_nav_link' href="#">
      <i class="fa-solid fa-utensils"></i>
      <h3>الأصناف</h3>
      </button>
      <div className={`admin_nav ${isNavCOpen ? 'right' : ''}`}>
        <button onClick={toggleNav}><i className="fas fa-arrow-right"></i></button>
        <div className='cart_orders_container'>
        {categories.map(category => (
                      <div className='nav_cat_item'>
                      <a className='categoty_nav_link' href={`/category/${category.id}`}><h2>{category.title}</h2></a>
                  </div>
          ))}
        </div>
      </div></>):(<></>)}

    </>
  );
};

export default NavCategories;
