import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable, { SortOrder } from 'react-data-table-component';
import ImageUploader from 'react-image-upload';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import 'react-image-upload/dist/index.css';
import '../styles/create_dish.css';

const CreateDish = () => {
  const [categories, setCategories] = useState([]);
  const [dishes, setDishes] = useState([]);

  const fetchCat = () => {
    axios.get(`https://back.eltaqwarestaurant.com/admin/categories`, {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => {
        console.log(response);
        setCategories(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        toast.error(error.response.data.message)
      });
    axios.get(`https://back.eltaqwarestaurant.com/admin/products`, {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => {
        console.log(response.data.data);
        setDishes(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        toast.error(error.response.data.message)
      });

  };

  useEffect(() => {
    fetchCat();
  }, []); // Empty dependency array to only run once


  const [dishData, setDishData] = useState({
    name: '',
    category: '',
    priceBeforeDiscount: null,
    finalPrice: null,
    description: '',
    image: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDishData({
      ...dishData,
      [name]: value,
    });
    console.log(dishData)
  };

  const getImageFileObject = (imageFile) => {
    setDishData({
      ...dishData,
      image: imageFile.file,
    });
  };

  const runAfterImageDelete = (file) => {
    console.log({ file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('category_id', dishData.category);
    formData.append('title', dishData.name);
    formData.append('initial_price', dishData.priceBeforeDiscount);
    formData.append('final_price', dishData.finalPrice);
    formData.append('description', dishData.description);
    formData.append('image', dishData.image);

    console.log(formData)

    try {
      const response = await axios.post('https://back.eltaqwarestaurant.com/admin/products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },

      });
      toast.success("تم انشاء الطبق بنجاح")
      fetchCat()
      setDishData({
        name: '',
        category: '',
        priceBeforeDiscount: '',
        finalPrice: '',
        description: '',
        image: null,
      });
      console.log(response.data);
    } catch (error) {
      console.error('Error uploading file:', error.response.data.message);
      toast.error(error.response.data.message)
    }
  };

  const handleDeleteProduct = async (id, e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(`https://back.eltaqwarestaurant.com/admin/products/${id}`,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },

      });
      toast.success("تم مسح الطبق بنجاح")
      console.log(response.data);
      fetchCat()
    } catch (error) {
      console.error('Error uploading file:', error.response.data.message);
      toast.error(error.response.data.message)
    }
};

  const columns = [
    {
      name: 'اسم الطبق',
      sortable: true,
      selector: row =>           <a
      data-tooltip-id="my-tooltip"
      data-tooltip-content={`${row.title}`}
      data-tooltip-place="top"
    >
      {row.title} ...
    </a>
    },
    {
      name: 'سعر قبل الخصم',
      sortable: true,
      selector: row => row.initial_price
    },
    {
      name: 'سعر بعد الخصم',
      sortable: true,
      selector: row => row.final_price
    },
    {
      name: 'قرارات',
      sortable: true,
      selector: row => <>
        <a href={`/product/${row.id}`} target="_blank" className="positive_karar"><i class="fa-solid fa-eye"></i></a>
        <button onClick={(e) => handleDeleteProduct(row.id, e)} className='negative_karar'><i class="fa-solid fa-trash"></i></button>
      </>
    },



  ];

  return (
    <>
      {categories && dishes ? (<>
        <div className='create_delete'>
          <form className="create_form" onSubmit={handleSubmit}>
            <div className="create_form_container">
              <h1>اضافة طبق جديد</h1>
              <div className="input_holder">
                <label>اسم الطبق</label>
                <input
                  className="input_input"
                  placeholder="أدخل اسم الطبق"
                  name="name"
                  value={dishData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input_holder">
                <label>تصنيف الطبق</label>
                <select
                  className="input_input"
                  name="category"
                  value={dishData.category}
                  onChange={handleInputChange}
                >
                  <option value="">اختر تصنيف الطبق</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input_holder">
                <label>السعر قبل العرض</label>
                <input
                  className="input_input"
                  type='number'
                  placeholder="أدخل أدخل سعر قبل العرض"
                  name="priceBeforeDiscount"
                  value={dishData.priceBeforeDiscount}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input_holder">
                <label>السعر النهائي</label>
                <input
                  className="input_input"
                  type='number'
                  placeholder="أدخل السعر النهائي"
                  name="finalPrice"
                  value={dishData.finalPrice}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input_holder">
                <label>وصف الطبق</label>
                <textarea
                  className="input_input"
                  placeholder="أدخل وصف الطبق"
                  name="description"
                  value={dishData.description}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input_holder">
                <label>صورة الطبق</label>
                <ImageUploader
                  className="input_img"
                  style={{ height: 220, width: 220, background: 'rgb(255 255 255)' }}
                  onFileAdded={(img) => getImageFileObject(img)}
                  onFileRemoved={(img) => runAfterImageDelete(img)}
                />
              </div>
              <input type="submit" className="input_submit" value="أضف الطبق" />
            </div>
            <ToastContainer />
          </form>
          <div className='table_div'>
            <DataTable
              columns={columns}
              data={dishes}
              pagination
              striped
              defaultSortAsc={false}
            />
            <Tooltip id="my-tooltip" place="top" />
          </div>
        </div>

      </>) : (<></>)}
    </>

  );
};

export default CreateDish;
